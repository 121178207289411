import React from 'react';
import { Container, Row, Col, Nav, NavLink } from "react-bootstrap"
import styles from './Footer.css';


export default function Footer() {
  return <footer>
    <Container fluid>
      <Row className='bg-dark text-white'>
        <Col className='mx-5'>
          <Nav className='flex-column fs-5'>
            <NavLink href='/about' className='text-white'>Impressum</NavLink>
          </Nav>
        </Col>
      </Row>
    </Container>
  </footer>;
}


