import {
  BrowserRouter as Router, Routes,
  Route
} from "react-router-dom";
import About from "./pages/impressum/impressum.tsx";
import Home from "./pages/home/home.tsx";
import DSGVO from "./pages/dsgvo/dsgvo.tsx";

import MyButton from "./components/MyButton/MyButton.js";
import Footer from "./components/Footer/Footer.tsx"
import SiteNav from "./components/SiteNav/SiteNav.tsx"

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'

function App() {
  return (
    <div className="app">
        
        
        <SiteNav/>
        <main>
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about"
                    element={<About />} />
            </Routes>
        </Router>
        </main>
        <Footer/>
    </div>)
}

export default App;
