import React from 'react';
import logo from './pforge.png';
import  './home.css';


export default function home() {
  return <div className="home">
    <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <p className='homeCopy'>&copy; 2014 - 2024 by <a href="http://www.pforge.de/">Phoenix Forge</a></p>
    </header>

  </div>;
}