// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home {
    
}

.homeCopy{
    font-size: calc(1px + 1vmin);
}

.homeFont{
    
}`, "",{"version":3,"sources":["webpack://./src/pages/home/home.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,4BAA4B;AAChC;;AAEA;;AAEA","sourcesContent":[".home {\n    \n}\n\n.homeCopy{\n    font-size: calc(1px + 1vmin);\n}\n\n.homeFont{\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
