// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.impressum {}

.impMain {
    background: linear-gradient(#282c34, #212732, #161c2a);
    min-height: calc(100vh - 102px);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}

.impRow{
    padding-top: 20px;
    height: calc(100vh - 150px);
    overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/impressum/impressum.css"],"names":[],"mappings":"AAAA,YAAY;;AAEZ;IACI,sDAAsD;IACtD,+BAA+B;IAC/B,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,2BAA2B;IAC3B,gBAAgB;AACpB","sourcesContent":[".impressum {}\n\n.impMain {\n    background: linear-gradient(#282c34, #212732, #161c2a);\n    min-height: calc(100vh - 102px);\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    color: white;\n}\n\n.impRow{\n    padding-top: 20px;\n    height: calc(100vh - 150px);\n    overflow-y: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
